import React from 'react';
import {PageColor} from '../components/page-color';
import {Content} from '../components/content';

export default class Services extends React.Component {
    constructor() {
        super();
        this.state = {};
    }

    get clients() {
        return this.props.pathContext.website.clients.split('\n').chunk(5);
    }

    render = () => (
        <main className="page--about">
            <PageColor color="green" nav="white" />
            <section className="services services--reverse">
                <div className="services__content">
                    <h1 className="service__title text-white">
                        <div className="title-poppins">About</div>
                        <div className="title-ivy">Us</div>
                    </h1>
                    <p className="text-white leading-8">
                        Creative brains, practical hands, analyzing heads and loving hearts: welcome to Native Nation. Located in Antwerp, we gather almost 15 experts in one mansion. For years, we’ve been the driving force behind the biggest influencer campaigns in Belgium. Clients as Belfius, Gaia, Danone, Jeep, TUI, PepsiCo and many more knocked on our always-open door, with effective and remarkable campaigns as a result.
                    </p>
                    <div className="services__content__down">
                        <i class="fa fa-chevron-down" aria-hidden="true"></i>
                    </div>
                </div>
                <div className="services__image">
                    <div className="services__image__block" />
                    <img src="/images/about/about1.jpeg" />
                </div>
            </section>
            <section className="services">
                <div className="services__content">
                    <h1 className="service__title text-white">
                        <div className="title-poppins">Our</div>
                        <div className="title-ivy">services</div>
                    </h1>
                    <p className="text-white leading-8">
                        Our main purpose upon today is helping brands achieve their digital goals. With the overload and clutter of social content, we push brands to take their online presence to the next level. By bringing strategy, creativity and media together, we synergize growth as your social media soulmate.
                        <br/>
                        As an official partner of SBS, we invest together in a more impactful future of social media. We combine offline and online, resulting in 360 degree campaigns. So, are you ready just like our other 50 clients to grow, glow & bloom? Then buckle up, ‘cause we are.
                    </p>
                </div>
                <div className="services__image">
                    <div className="services__image__block"/>
                    <img src="/images/about/about2.jpeg"/>
                </div>
            </section>
        </main>
    );
}
